<template>
	<div>
		<el-dialog :draggable="true" width="40%" @close="Reset" :destroy-on-close="true" v-model="outerVisible"
			:title="title">
			<div class="dialog">
				<el-form ref="ruleForm" label-width="80px">
					<el-form-item label="头像">
						<span class="xx"></span>
						<div class="dialog-up">
							<!-- 预览图 -->
							<img v-if="ruleForm.headimg" class="dialog-img" :src="ruleForm.headimg" />
							<p v-if="ruleForm.headimg" class="dele" @click="dele">删除</p>
							<el-upload accept=".jpg,.png" :headers="Headers" :action="ActionUrl"
								:on-success="handleAvatarSuccess">
								<div class="upload">
									<i class="icon iconfont icon-shangchuan"></i>
								</div>
							</el-upload>
						</div>
					</el-form-item>
					<el-form-item label="排序">
						<span class="xx"></span>
						<el-input clearable v-model="ruleForm.sort" placeholder="请输入排序"></el-input>
					</el-form-item>

					<el-form-item label="手机号码">
						<span class="xx"> </span>
						<el-input type="number" clearable v-model="ruleForm.phone" placeholder="请输入手机号码"></el-input>
					</el-form-item>
					<el-form-item label="角色">
						<span class="xx">*</span>
						<el-select clearable v-model="ruleForm.role_ids" placeholder="请选择角色">
							<el-option v-for="item in grouplist" :key="item.id" :label="item.name" :value="item.id">
							</el-option>
						</el-select>
					</el-form-item>

					<el-form-item label="姓名">
						<span class="xx">*</span>
						<el-input clearable v-model="ruleForm.name" placeholder="请输入姓名"></el-input>
					</el-form-item>

					<el-form-item label="用户名">
						<span class="xx">*</span>
						<el-input :disabled="title=='编辑'?true:false" onkeyup="value=value.replace(/[^\w\.\/]/ig,'')"
							clearable v-model="ruleForm.username" placeholder="请输入用户名(不允许输入中文和空格)"></el-input>
					</el-form-item>

					<el-form-item label="密码" v-if="title=='新增'">
						<span class="xx">*</span>
						<el-input type="password" show-password clearable v-model="ruleForm.password"
							placeholder="请输入8到16位数字与字母组合密码"></el-input>
					</el-form-item>

					<el-form-item label="确认密码" v-if="title=='新增'">
						<span class="xx">*</span>
						<el-input type="password" show-password clearable v-model="ruleForm.qrpassword"
							placeholder="请输入确认密码">
						</el-input>
					</el-form-item>

					<el-form-item label="状态">
						<span class="xx">*</span>
						<el-select clearable v-model="ruleForm.status" placeholder="请选择状态">
							<el-option label="启用" :value="1"></el-option>
							<el-option label="禁用" :value="0"></el-option>
						</el-select>
					</el-form-item>
				</el-form>
			</div>
			<template #footer>
				<div class="dialog-footer">
					<el-button size="mini" @click="outerVisible = false">取消</el-button>
					<el-button size="mini" class="dialog-btn el-white" type="primary" @click="SubmitForm">确定
					</el-button>
				</div>
			</template>
		</el-dialog>
	</div>
</template>

<script>
	import {
		ElMessage
	} from 'element-plus'
	import {
		request,
		api
	} from "@/api/user";

	export default {
		data() {
			return {
				title: '',
				outerVisible: false,
				Headers: {
					token: ''
				},
				cunlist: [],
				ActionUrl: '',
				grouplist: [],
				ruleForm: {
					username: '',
					password: '',
					name: '',
					role_ids: '',
					status: 1,
					headimg: '',
					sort: 0,
					qrpassword: '',
					phone: '',
				},
			}
		},
		mounted() {
			// 上转图片url
			this.Headers.token = sessionStorage.getItem('token')
			this.Headers.appid = sessionStorage.getItem('appid')
			this.Headers.nonce = sessionStorage.getItem('nonce')
			this.Headers.timestamp = sessionStorage.getItem('timestamp')
			this.Headers.sign = sessionStorage.getItem('sign')

			this.ActionUrl = sessionStorage.getItem('baseURL') + '/system/Upload/FormUpload'
		},
		methods: {
			// 获取角色列表
			getList() {
				this.$http.post(api.rolelist, {
						page: 1,
						limit: 999
					})
					.then((res) => {
						if (res.code == 200) {
							this.grouplist = res.data.list
						}
					})
			},
			// 删除图片
			dele() {
				this.ruleForm.headimg = ''
			},
			// 上转图片
			handleAvatarSuccess(res, file) {
				this.ruleForm.headimg = res.data.url
			},
			// 获取用户信息
			getuser() {
				this.$http.post(api.info, {
						id: this.ruleForm.id
					})
					.then((res) => {
						if (res.code == 200) {
							this.ruleForm = res.data
							this.ruleForm.role_ids = Number(res.data.role_ids)
						}
					})
			},
			// 清空数据
			Reset() {
				for (let key in this.ruleForm) {
					this.ruleForm[key] = ''
				}
				this.ruleForm.status = 1
				delete this.ruleForm.id
				delete this.ruleForm.delete_time
			},
			//新增/编辑
			SubmitForm() {
				if (this.ruleForm.role_ids === '') {
					ElMessage({
						message: '请选择角色',
						type: 'warning',
					})
				} 
				else if (this.ruleForm.name === '') {
					ElMessage({
						message: '请输入姓名',
						type: 'warning',
					})
				}
				else if (this.ruleForm.username === '') {
					ElMessage({
						message: '请输入用户名',
						type: 'warning',
					})
				} else if (this.ruleForm.password === '' && this.title == '新增') {
					ElMessage({
						message: '请输入密码',
						type: 'warning',
					})
				} else if (!(/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/.test(this.ruleForm.password)) && this.title ==
					'新增') {
					ElMessage({
						message: '请输入8到16位数字与字母组合密码',
						type: 'warning',
					})
				} else if (this.ruleForm.qrpassword === '' && this.title == '新增') {
					ElMessage({
						message: '请输入确认密码',
						type: 'warning',
					})
				} else if (this.ruleForm.qrpassword != this.ruleForm.password && this.title == '新增') {
					ElMessage({
						message: '密码不一致',
						type: 'warning',
					})
				} else {
					if (this.title == '新增') {
						this.add()
					} else {
						this.edit()
					}

				}

			},
			// 编辑
			edit() {
				this.$http.post(api.edit, this.ruleForm)
					.then((res) => {
						if (res.code == 200) {
							ElMessage({
								type: 'success',
								message: '编辑成功',
							})
							this.$EventBus.emit('RefreshUser')
							this.outerVisible = false
							// 刷新列表
							this.$emit('SubmitForm')
						}
					})
			},
			// 新增
			add() {
				this.$http.post(api.add, this.ruleForm)
					.then((res) => {
						if (res.code == 200) {
							ElMessage({
								type: 'success',
								message: '新增成功',
							})
							this.outerVisible = false
							// 刷新列表
							this.$emit('SubmitForm')
						}
					})
			},
			// 显示隐藏
			show(val, id) {
				this.getList()
				if (id) {
					this.ruleForm.id = id
					this.getuser()
				}
				this.title = val
				this.outerVisible = true
			}
		}
	}
</script>

<style scoped>
</style>